<template>
  <div class="zxfw">
    <div class="header">
      <div class="h1">中介服务</div>
      <div class="h2">全面・便捷・专业 助力企业成长</div>
    </div>
    <div class="content">
      <div class="h3">
        <el-row>
          <el-col :span="24/types.length" v-for="(pro,index) in types" :key="index">
            <div class="pro" @click="goto(pro)">
              <div>
                <div class="img"><el-image :src="pro.image"></el-image></div>
                <div class="text text-overflow">{{pro.name}}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="bg0">
      <div  class="t1 flex">
        <div class="t1left flex-1">
          <div style="font-size: 24px;font-weight: bolder;margin-bottom: 20px">{{datas.introTitle}}</div>
          <div v-html="datas.introText"></div>
          <div style="margin-top: 35px">
            <el-button type="primary"   @click="goto">查看全部咨询服务</el-button>
          </div>
        </div>
        <div class="t1right"> <el-image fit="cover"  :src="datas.introImage"></el-image></div>
      </div>
    </div>
    <div :style="`background:url(${datas.serviceImage}) top center no-repeat;background-size:100% 100%;height:700px;`">
      <div class="zhuti">
        <div class="titles" style="padding-top: 56px">
          咨询服务
          <div class="titles2">提供丰富咨询服务，提升企业经营管理能力</div>
        </div>
      </div>
    </div>
    <div>
      <div class="zhuti">
        <div class="titles" style="padding: 56px 0">
          咨询服务行业
        </div>
        <div class=" allfangan">
          <div class="fangan" v-for="(li,index) in arr" :key="index">
            <div class="image"><el-image  :src="li.image"></el-image></div>
            <div>
              <div class="title text-overflow">{{li.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="t2">
      <div  class="bg">
        <div class="zhuti">
          <div class="titles" style="padding: 56px 0">
            咨询服务数据统计
            <div class="titles2">关注人才培训政策</div>
          </div>
          <div class="tubiao" ref="main">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {intermediaryindex} from "../../request/moudle/zxfw";

export default {
  name: "gonggaoxq",
  data(){
    return {
      arr:[],
      types:[],
      datas:''
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    loadconfig(){
      this.$api.zxfw.intermediaryindex().then((res)=>{
        this.datas=res.data
        this.types=res.data.categoryList
        this.arr=res.data.industryList
        this.init()
      })
    },
    init(){
      const color=['#257DB2','#12AC93','#9CB952','#F19B14','#BD372B','#65C5E3']
      const arr=[]
      for(let i=0;i<this.datas.count.x.length;i++){
        const obj={
          value:this.datas.count.x[i],
          itemStyle: {
            color: color[i]?color[i]:'#65C5E3',
          },
        }
        arr.push(obj)
      }
      const options={
        xAxis: {
          type: 'value',
          name: '(咨询浏览量)',
        },
        tooltip: {
          show:true
        },
        yAxis: {
          data:this.datas.count.y,
        },
        series: [
          {
            data:arr,
            itemStyle: {
              borderRadius:8
            },
            type: 'bar',
          },
        ]
      }
      this.$nextTick(()=>{
        const  myCharts = this.$echarts.init(this.$refs.main);
        myCharts.setOption(options);
      })
    },
    goto(li){
      this.$router.push({
        path:'/zxfwlist',
        query:{
          typeId:li.id ||''
        }
      })
    },
    golink(item){
      if(item.link){
        window.open(item.link,'_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table th.is-leaf{
  background-color: #fff!important;
}
.tubiao{
  width: 100%;
  height: 320px;
}
.zhuti{
  width: 1200px;
  margin: 0 auto;
}
.pro1{
  float: left;
  width: 203px;
  margin-left: 10px;
  cursor: pointer;
  .img1{
    width: 203px;
    height: 114px;
  }
  .text1{
    font-size: 12px;
    margin-top: 10px;
  }
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 35px;
  .titles2{
    color: #292929;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
  }
}
.content{
  background: #fff;
  padding: 20px 0;
}
.h3{
  width: 1200px;
  margin: 30px auto;
  overflow: hidden;
  text-align: center;
  .pro{
    cursor: pointer;
    .img{
      margin: 0 auto;
      width: 60px;
      height: 60px;
    }
    .text{
      font-size: 16px;
      color: #000000;
      margin-top: 20px;
      text-align: center;
    }
  }
}
.allfangan{
  overflow: hidden;
  .fangan{
    float: left;
    width: 290px;
    margin: 5px;
    .image{
      width: 100%;
      height: 180px;
      margin-right: 32px;
    }
    .title{
      color: #1D1D1D;
      font-weight: bolder;
      font-size: 16px;
      margin: 15px 0;
    }
    .h2{
      height: 37px;
      color: #999;
    }
  }
}
.zxfw {
  background: #fff;
  .header {
    height: 220px;
    background: url("../../assets/banner/zxpx.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 90px;
    text-align: center;
    color: #fff;

    .h1 {
      font-size: 42px;
      font-weight: 500;
    }

    .h2 {
      font-size: 22px;
      line-height: 80px;
    }



  }
  .bg0{
    background:linear-gradient(0deg,#FFFFFF,#F0F5FE);
  }
  .bg1{
    background: #fff;
  }
  .bg3{
    background:url("../../assets/zxpx/img.png") no-repeat;
    background-size: 100% 100%;
    padding: 56px 0;
  }
  .t1{
    width: 1200px;
    margin: 0 auto;
    padding: 56px 0;
    .t1left{
      padding: 60px;
      line-height: 35px;
    }
    .button{
      width: 150px;
      margin: 50px auto;
      padding: 12px 45px;
      border-radius: 6px;
      background: #E9C79A;
      font-size: 16px;
      font-weight: 550;
      color: #fff;
      cursor: pointer;
    }
    .t1right{
      width: 641px;
      height: 432px;
      margin-top: 55px;
    }
  }
  .t2{
    background: #fff;
    .t2content{
      width: 1200px;
      margin: 0 auto;
      padding: 50px 0;
    }
    .t2left{
      width: 450px ;
      height: 320px;
      position: relative;
      cursor: pointer;
      .image{
        height: 100%;
      }
    }
    .t2list{
      float: left;
      width: 230px;
      cursor: pointer;
      .img{
        height: 130px;
        margin-left: 15px;
        background: #0078F2;
      }
      .text{
        margin: 10px 15px;
      }
    }
  }
  .t3{
    width: 1200px;
    margin: 0px auto;
    overflow: hidden;
    padding-bottom: 152px;
    .mingren{
      width: 243px;
      height: 640px;
      float: left;
      background: url("../../assets/home/shi.png");
      background-size: 100% 100%;
      margin-right: 50px;
    }
  }
  .news{
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 100px;
    overflow: hidden;
    .newlist{
      width: 280px;
      height: 235px;
      float: left;
      margin-right: 20px;
      position: relative;
      margin-bottom: 20px;
      cursor: pointer;
      .img{
        height: 180px;
        background: #F1F3F3;
      }
    }
  }
  .desc{
    background: rgba(0,0,0,0.7);
    opacity: 0.6;
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .title{
      font-weight: 550;
    }
    .title2{
      text-align: right;
      font-size: 12px;
    }
  }
}
</style>